export const NEW = 'new';
export const STATUS_STUDIO_IN = 'studio_in';
export const STATUS_SHOOT_PENDING = 'shoot_pending';
export const STATUS_SHOOT_DONE = 'shoot_done';
export const STATUS_SHOOT_REJECTED = 'shoot_rejected';
export const STATUS_STUDIO_OUT = 'studio_out';

export const STATUSES_DATA = {
  new: { color: '#922c88', label: 'New' },
  studio_in: { color: '#4556ac', label: 'Studio In' },
  shoot_pending: { color: '#ff6b00', label: 'Shoot Pending' },
  shoot_done: { color: '#0091D5', label: 'Shoot Done' },
  shoot_rejected: { color: '#FF0000', label: 'Shoot Rejected' },
  studio_out: { color: '#3e884f', label: 'Studio Out' },
};
export const STATUSES = [
  { code: NEW, label: 'New', color: STATUSES_DATA.new.color },
  {
    code: STATUS_STUDIO_IN,
    label: 'Studio In',
    color: STATUSES_DATA.studio_in.color,
  },
  {
    code: STATUS_SHOOT_PENDING,
    label: 'Shoot Pending',
    color: STATUSES_DATA.shoot_pending.color,
  },
  {
    code: STATUS_SHOOT_DONE,
    label: 'Shoot Done',
    color: STATUSES_DATA.shoot_done.color,
  },
  {
    code: STATUS_SHOOT_REJECTED,
    label: 'Shoot rejected',
    color: STATUSES_DATA.shoot_rejected.color,
  },
  {
    code: STATUS_STUDIO_OUT,
    label: 'Studio Out',
    color: STATUSES_DATA.studio_out.color,
  },
  // { code: STATUS_SHOOT_APPROVED, label: 'Shoot Approved' }
];

export const COUNTRY_OF_ORIGIN = [
  { code: 'CHN', label: 'China' },
  { code: 'IND', label: 'India' },
];

export const FILTERS = [
  { code: 'track_status', label: 'Track Status', content: STATUSES },
  {
    code: 'countryOfOrigin',
    label: 'Country Of Origin',
    content: COUNTRY_OF_ORIGIN,
  },
];

export const DEFAULT_ERROR_MSG = 'Something went wrong';

export const MODEL_SHOOT_DONE = 1;
export const STILL_SHOOT_DONE = 2;
export const { STYLI_APP_AUTH_SERVICE_URL } = process.env;
